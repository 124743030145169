import {
  InvestorType,
  MaritalStatus,
  TaxJurisdiction,
} from "enums/common/investors";

interface EntityDetails {
  entity_name: string;
  entity_phone: string;
  entity_email: string;
  entity_registered_address: string;
  entity_mailing_address: string;
}

interface EntityAuthorizedPerson {
  auth_person_name: string;
  auth_person_title: string;
  auth_person_email: string;
  auth_person_phone: string;
}

interface EntityRegulatoryDetails {
  legal_entity_type: string;
  reg_body: string;
  reg_number: string;
  reg_jurisdiction: string;
  cra_business_id: string;
  directors: string;
}

interface IndividualDetails {
  individual_name: string;
  individual_phone: string;
  individual_email: string;
  individual_address: string;
  individual_mailing_address: string;

  individual_occupation: string;
  individual_dob: string;
  individual_marital_status: MaritalStatus;
  individual_dependents: number;

  individual_registered: boolean;

  individual_insider: boolean;
  individual_insider_name: string;
  individual_insider_issuer: string;
  individual_insider_position: string;

  individual_acknowledge_rdi: boolean;
}

interface IndividualSpouse {
  spouse_name: string;
  spouse_occupation: string;
}
interface IndividualKyc {
  spouse_net_worth: number;
  spouse_net_financial_assets: number;
  spouse_annual_income: number;

  individual_borrowed_funds: boolean;

  individual_financial_assets_percent: number;

  individual_cash_percent: number;
  individual_stocks_percent: number;

  individual_bonds_percent: number;
  individual_other_percent: number;
}

export interface Investor
  extends Partial<EntityDetails>,
    Partial<EntityAuthorizedPerson>,
    Partial<EntityRegulatoryDetails>,
    Partial<IndividualDetails>,
    Partial<IndividualSpouse>,
    Partial<IndividualKyc> {
  _id: string;
  _created: string;
  tenant: string;

  type: InvestorType;

  net_assets_over_25MM: boolean;
  waive_suitability_via_assets: boolean;
  waive_suitability_via_permitted_client: boolean;

  tax_jurisdiction: TaxJurisdiction;
  tax_jurisdiction_other: string;

  third_parties: boolean;
  third_parties_desc: string;

  permitted_client: string[];
  accredited_investor: string[];

  net_worth: number;
  net_financial_assets: number;
  annual_income: number;
  liquidity_needs: number;

  investment_objective: string;

  time_horizon: string;

  transaction_freq: string;

  investment_knowledge: string;

  risk_profile: string;

  pol_exposed: boolean;
  pol_exposed_description: string;

  pol_exposed_family: boolean;
  pol_exposed_family_description: string;

  pol_exposed_spouse: boolean;
  pol_exposed_spouse_description: string;

  // beneficial owners
  pol_exposed_owner: boolean;
  pol_exposed_owner_description: string;
}

export const DEFAULT_INVESTOR: Partial<Investor> = {
  type: InvestorType.Entity,

  pol_exposed: false,
  pol_exposed_family: false,
  pol_exposed_spouse: false,
  pol_exposed_owner: false,

  net_assets_over_25MM: false,
  waive_suitability_via_assets: false,
  waive_suitability_via_permitted_client: false,

  third_parties: false,

  individual_registered: false,
  individual_insider: false,
  individual_borrowed_funds: false,
  individual_marital_status: MaritalStatus.Single,
  individual_acknowledge_rdi: false,

  net_worth: 0,
  net_financial_assets: 0,
  annual_income: 0,
  liquidity_needs: 0,

  spouse_net_worth: 0,
  spouse_net_financial_assets: 0,
  spouse_annual_income: 0,

  individual_dependents: 0,

  individual_financial_assets_percent: 0,

  individual_cash_percent: 0,
  individual_stocks_percent: 0,

  individual_bonds_percent: 0,
  individual_other_percent: 0,
};
