import {
  EnvelopeClosedIcon,
  MobileIcon,
  SewingPinFilledIcon,
} from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { MaritalStatus, hasSpouse } from "enums/common/investors";
import { FormHelper } from "../form.helper";
import IndividualSpouse from "./spouse";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const IndividualDetails = (props: Props) => {
  const investor = props.cookiesCx.investor;
  const nonSingle = hasSpouse(investor.individual_marital_status);

  return (
    <ErrorBoundary id="IndividualDetails">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("Individual Details")}

        {FormHelper.renderTextField({
          label: "Name",
          defaultValue: investor.individual_name,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_name: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <MobileIcon />,
          label: "Phone",
          defaultValue: investor.individual_phone,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_phone: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <EnvelopeClosedIcon />,
          label: "Email",
          defaultValue: investor.individual_email,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_email: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <SewingPinFilledIcon />,
          label: "Address",
          defaultValue: investor.individual_address,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_address: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <SewingPinFilledIcon />,
          label: "Mailing Address (if different)",
          defaultValue: investor.individual_mailing_address,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_mailing_address: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          label: "Occupation",
          defaultValue: investor.individual_occupation,
          placeholder:
            "(Be specific--if retired, please enter your previous occupation)",
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_occupation: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          label: "Date of Birth",
          defaultValue: investor.individual_dob,
          placeholder: "YYYY-MM-DD",
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_dob: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderSelect({
          id: "individual_marital_status",
          label: "Marital Status",
          placeholder: "Select one",
          defaultValue: investor.individual_marital_status,
          disabled: props.disabled,
          options: Object.values(MaritalStatus).map((v) => ({
            i18Key: v,
            value: v,
          })),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_marital_status: value as MaritalStatus,
            }),
        })}

        {nonSingle && <IndividualSpouse {...props} />}

        {FormHelper.renderTextField({
          label: "Number of Dependents",
          defaultValue: investor.individual_dependents?.toFixed(0),
          disabled: props.disabled,
          onChange: (e) => {
            const n = parseInt(e.target.value);

            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_dependents: n,
            });
          },
        })}
      </Flex>
    </ErrorBoundary>
  );
};

export default IndividualDetails;
