import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookiesInterface } from "contexts/cookies";
import EntityAuthorizedPerson from "./authorized-person";
import EntityDetails from "./details";
import EntityRegulatoryDetails from "./regulatory-details";
import EntityWaiverForm from "./waiver";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const EntityForm = (props: Props) => {
  return (
    <ErrorBoundary id="EntityForm">
      <Flex direction="column" gap="4">
        <EntityDetails {...props} />
        <EntityAuthorizedPerson {...props} />
        <EntityRegulatoryDetails {...props} />
        <EntityWaiverForm {...props} />
      </Flex>
    </ErrorBoundary>
  );
};

export default EntityForm;
