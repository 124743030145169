import { Investor } from "interfaces/common/investor";
import { BaseRESTService } from "services/_base-rest";

export class InvestorsService extends BaseRESTService {
  private static instance: InvestorsService;

  static getInstance(): InvestorsService {
    if (!InvestorsService.instance) {
      InvestorsService.instance = new InvestorsService();
    }

    return InvestorsService.instance;
  }

  private constructor() {
    super({
      controller: "investors",
    });
  }

  async getByTenant(tenant: string): Promise<Partial<Investor>[]> {
    return await this.get({
      uri: "query",
      params: {
        tenant: tenant,
      } as any,
    });
  }

  async exportByTenant(tenant: string): Promise<string> {
    return await this.get({
      uri: "export",
      params: {
        tenant: tenant,
      } as any,
    });
  }

  async postOne(payload: Partial<Investor>): Promise<Partial<Investor>> {
    return await this.post(
      {
        uri: "create",
      },
      payload
    );
  }
}
