import { Box, Container, Flex, Link, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { t } from "i18next";
import "./disclaimer.scss";

const ENABLE_LINKS = false;

interface NavButton {
  i18Key: string;
  onClick: () => void;
}

const NAV_BUTTONS: NavButton[] = [
  {
    i18Key: "contact-support",
    onClick: () => {
      console.debug(`Clicked contact & support`);
    },
  },
  {
    i18Key: "terms-of-use",
    onClick: () => {
      console.debug(`Clicked terms of use`);
    },
  },
  {
    i18Key: "relationship-disclosure",
    onClick: () => {
      console.debug(`Clicked relationship disclosure`);
    },
  },
  {
    i18Key: "privacy-policy",
    onClick: () => {
      console.debug(`Clicked privacy policy`);
    },
  },
];

const Disclaimer = () => {
  const renderButton = (b: NavButton) => (
    <Link
      key={`header-${b.i18Key}`}
      style={{ color: "white", whiteSpace: "nowrap" }}
      onClick={() => b.onClick()}
    >
      {t(b.i18Key)}
    </Link>
  );

  return (
    <ErrorBoundary id="Disclaimer">
      <div id="Disclaimer">
        <Container size="3">
          <Flex direction="row" mt="8" mb="8" gap="4">
            <Box>
              <Flex direction="column" gap="3">
                <Text>{t("disclaimer-1")}</Text>
                {/* <Text>{t("disclaimer-2")}</Text> */}
                {/* <Text>{t("disclaimer-3")}</Text> */}
                <Text>{t("copyright-x", { x: new Date().getFullYear() })}</Text>
              </Flex>
            </Box>
            {ENABLE_LINKS && (
              <Box width="min-content">
                <Flex direction="column" gap="2">
                  {NAV_BUTTONS.map((b) => renderButton(b))}
                </Flex>
              </Box>
            )}
          </Flex>
        </Container>
      </div>
    </ErrorBoundary>
  );
};

export default Disclaimer;
