import { FileIcon } from "@radix-ui/react-icons";
import { Button, Dialog, Flex, Table } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { Investor } from "interfaces/common/investor";

interface Props {
  investor: Partial<Investor>;
}

const InvestorDetailsDialog = (props: Props) => {
  const details: { label: string; value?: string }[] = [
    {
      label: "Type",
      value: props.investor.type,
    },
    {
      label: "Name",
      value: props.investor.entity_name ?? props.investor.individual_name,
    },
    {
      label: "Email",
      value: props.investor.entity_email ?? props.investor.individual_email,
    },
    {
      label: "Phone",
      value: props.investor.entity_phone ?? props.investor.individual_phone,
    },
    {
      label: "Address",
      value:
        props.investor.entity_registered_address ??
        props.investor.individual_address,
    },
    {
      label: "Over $25MM AUM",
      value: props.investor.net_assets_over_25MM ? "Yes" : "No",
    },
    {
      label: "Permitted Client",
      value: props.investor.permitted_client ? "Yes" : "No",
    },
    {
      label: "Waive Suitability",
      value:
        props.investor.waive_suitability_via_permitted_client ||
        props.investor.waive_suitability_via_assets
          ? "Yes"
          : "No",
    },
    {
      label: "Politically Exposed",
      value: props.investor.pol_exposed ? "Yes" : "No",
    },
  ];

  return (
    <ErrorBoundary id="InvestorDetailsDialog">
      <Dialog.Root>
        <Dialog.Trigger>
          <Button variant="soft">
            <FileIcon />
          </Button>
        </Dialog.Trigger>

        <Dialog.Content>
          <Dialog.Title>Investor Details</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            <Table.Root>
              <Table.Body>
                {details.map((row, i) => (
                  <Table.Row key={`investor-detail-${i}`}>
                    <Table.Cell>{row.label}</Table.Cell>
                    <Table.Cell>{row.value}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </Dialog.Description>

          <Dialog.Close>
            <Flex justify="end">
              <Button aria-label="Close">Close</Button>
            </Flex>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Root>
    </ErrorBoundary>
  );
};

export default InvestorDetailsDialog;
