import { Callout, Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const EntityWaiverForm = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="EntityWaiverForm">
      <Flex direction="column" gap="4">
        {FormHelper.renderRadio({
          id: "net_assets_over_25MM",
          label:
            "Are you a person or company, other than an individual or an investment fund, that has net assets of at least $25 million as shown on its most recently prepared financial statements?",
          defaultValue: investor.net_assets_over_25MM ? "true" : "false",
          disabled: props.disabled,
          options: [
            { i18Key: "Yes", value: "true" },
            { i18Key: "No", value: "false" },
          ],
          onValueChange: (value) => {
            const nextValue = value === "true";
            props.cookiesCx.setCookie(CookieKey.Investor, {
              net_assets_over_25MM: nextValue,
              waive_suitability_via_assets: !nextValue
                ? false
                : investor.waive_suitability_via_assets,
            });
          },
        })}

        {investor.net_assets_over_25MM && (
          <Callout.Root>
            <Callout.Text>
              {FormHelper.renderRadio({
                id: "waive_suitability_via_assets",
                label:
                  "If you qualify as a Permitted Client, do you wish to waive the suitability determination on your investments?",
                defaultValue: investor.waive_suitability_via_assets
                  ? "true"
                  : "false",
                disabled: props.disabled,
                options: [
                  {
                    i18Key:
                      "Yes, I wish to waive the suitability determination",
                    value: "true",
                  },
                  {
                    i18Key:
                      "No, I would like for a suitability determination to be made and will provide all the required KYC information",
                    value: "false",
                  },
                ],
                onValueChange: (value) =>
                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    waive_suitability_via_assets: value === "true",
                  }),
              })}
            </Callout.Text>
          </Callout.Root>
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export default EntityWaiverForm;
