import { Flex, Heading, Table, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { t } from "i18next";

const KNOWLEDGES: { level: string; description: string }[] = [
  {
    level: "Expert",
    description: `Comprehensive and specific knowledge of real estate and mortgage investments and the associated risks.`,
  },
  {
    level: "High",
    description: `Good understanding of real estate and mortgage investments and the levels of associated risks.`,
  },
  {
    level: "Moderate",
    description: `Some knowledge and experience with real estate and mortgage investments and the associated risks.`,
  },
  {
    level: "Low",
    description: `Know only the basic attributes of real estate and mortgage investment and the associated risks.`,
  },
];

const RISKS: { level: string; description: string }[] = [
  {
    level: "High",
    description: `You are willing to accept the risk of significant loss to the capital in the account and a high level of volatility in the value of the account. You have sufficient assets and liquidity to cover financial needs despite any loss incurred.`,
  },
  {
    level: "Medium",
    description: `You are willing to accept some risk of loss to the capital in the account and are willing to accept some volatility in the value of the account. You have sufficient assets and liquidity to cover financial needs despite any loss incurred.`,
  },
  {
    level: "Low",
    description: `Your priority is to preserve the capital in the account and are generally unwilling to accept volatility in the value of the account. You may have a short investment time horizon, limited future earning capacity, and you may not have sufficient assets and liquidity to handle a potential loss.`,
  },
];

const Footnotes = () => {
  const renderSubHeading = (i18Key: string) => (
    <Heading size="4">{t(i18Key)}</Heading>
  );

  return (
    <ErrorBoundary id="Footnotes">
      <Flex direction="column" gap="4">
        <Heading>{t("Footnotes")}</Heading>

        {renderSubHeading("Investment Knowledge")}

        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Level</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {KNOWLEDGES.map((k, i) => (
              <Table.Row key={`knowledge-${i}`}>
                <Table.Cell>{k.level}</Table.Cell>
                <Table.Cell>{k.description}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

        {renderSubHeading("Risk Profile")}

        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Level</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Description</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {RISKS.map((k, i) => (
              <Table.Row key={`risk-${i}`}>
                <Table.Cell>{k.level}</Table.Cell>
                <Table.Cell>{k.description}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

        {renderSubHeading("Net Financial Assets")}

        <Text>
          The value of your cash, cash equivalents, and securities (e.g. stock,
          bonds, mutual funds, private equity) minus all liabilities you have.
        </Text>
        <Text>
          Your home and other real estate should not be included in the Net
          Financial Asset calculation.
        </Text>

        {renderSubHeading("Net Worth")}

        <Text>
          The value of all your assets (including real estate) minus all your
          liabilities.
        </Text>

        {renderSubHeading("Liquidity Needs")}

        <Text>
          A large purchase or expense that could affect the allocation of your
          monthly net income (e.g. home purchase, financial support to be paid,
          education, medical).
        </Text>

        {renderSubHeading("Political Exposure")}

        <Text>
          A "politically exposed domestic person" is an individual who holds or
          within the last 5 years has held one of the following offices or
          positions in or on behalf of the Canadian federal government, a
          Canadian provincial government, or a Canadian municipal government:
          (i) Governor General, lieutenant governor or head of government; (ii)
          member of the Senate or House of Commons or member of a legislature;
          (iii) deputy minister or equivalent rank; (iv) ambassador, or attaché
          or counsellor of an ambassador; (v) military officer with a rank of
          general or above; (vi) president of a corporation that is wholly owned
          directly by Her Majesty in right of Canada or a province; (vii) head
          of a government agency; (viii) judge of an appellate court in a
          province, the Federal Court of Appeal or the Supreme Court of Canada;
          (ix) leader or president of a political party represented in a
          legislature; or (x) mayor.
        </Text>

        <Text>
          A "politically exposed foreign person" is an individual who holds or
          has ever held one of the following offices or positions in or on
          behalf of a foreign country: (i) head of state or head of government;
          (ii) member of the executive council of government or member of a
          legislature; (iii) deputy minister or equivalent rank; (iv)
          ambassador, or attaché or counsellor of an ambassador; (v) military
          officer with a rank of general or above; (vi) president of a
          state-owned company or a state-owned bank; (vii) head of a government
          agency; (viii) judge of a supreme court, constitutional court or other
          court of last resort; or (ix) leader or president of a political party
          represented in a legislature.
        </Text>

        {renderSubHeading("Head of an International Organization")}

        <Text>
          A "head of an international organization" is a head of an
          international organization that is established by the governments of
          states or the head of an institution of any such organization.
        </Text>

        {renderSubHeading("Person")}

        <Text>
          "Person" includes an individual, corporation, partnership, party,
          trust, fund, association and any other organized group of persons and
          the personal or other legal representative of a person to whom the
          context can apply according to law.
        </Text>
      </Flex>
    </ErrorBoundary>
  );
};

export default Footnotes;
