import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { InvestorType } from "enums/common/investors";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const PoliticalExposureForm = (props: Props) => {
  const investor = props.cookiesCx.investor;

  const renderInputs = () => {
    switch (investor.type) {
      case InvestorType.Individual: {
        return (
          <>
            {FormHelper.renderSelect({
              id: "pol_exposed",
              label:
                "Are you a politically exposed domestic person, a politically exposed foreign person or a head of an international organization?",
              placeholder: "Select one",
              hint: "See footnotes",
              defaultValue: investor.pol_exposed ? "true" : "false",
              disabled: props.disabled,
              options: [
                { i18Key: "Yes", value: "true" },
                { i18Key: "No", value: "false" },
              ],
              onValueChange: (value) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  pol_exposed: value === "true",
                }),
            })}

            {investor.pol_exposed &&
              FormHelper.renderTextArea({
                label:
                  "Please provide details: (i.e: full name, position held, dates of holding the position, name of organization)",
                placeholder: "Please describe",
                defaultValue: investor.pol_exposed_description,
                disabled: props.disabled,
                onChange: (e) =>
                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    pol_exposed_description: e.target.value ?? "",
                  }),
              })}

            {FormHelper.renderSelect({
              id: "pol_exposed_family",
              label:
                "Is any one or more of your family members (mother or father, child, spouse or common-law partner, spouse’s or common-law partner’s mother or father, or brother, sister, half-brother or half-sister) a politically exposed domestic person, a politically exposed foreign person or a head of an international organization?",
              placeholder: "Select one",
              hint: "See footnotes",
              defaultValue: investor.pol_exposed_family ? "true" : "false",
              disabled: props.disabled,
              options: [
                { i18Key: "Yes", value: "true" },
                { i18Key: "No", value: "false" },
              ],
              onValueChange: (value) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  pol_exposed_family: value === "true",
                }),
            })}

            {investor.pol_exposed_family &&
              FormHelper.renderTextArea({
                label:
                  "Please provide details: (i.e: full name, position held, dates of holding the position, name of organization)",
                placeholder: "Please describe",
                defaultValue: investor.pol_exposed_family_description,
                disabled: props.disabled,
                onChange: (e) =>
                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    pol_exposed_family_description: e.target.value ?? "",
                  }),
              })}

            {FormHelper.renderSelect({
              id: "pol_exposed_spouse",
              label:
                "Is any person that you or your spouse are closely associated with, for personal or business reasons, a politically exposed domestic person, a politically exposed foreign person or a head of an international organization?",
              placeholder: "Select one",
              hint: "See footnotes",
              defaultValue: investor.pol_exposed_spouse ? "true" : "false",
              disabled: props.disabled,
              options: [
                { i18Key: "Yes", value: "true" },
                { i18Key: "No", value: "false" },
              ],
              onValueChange: (value) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  pol_exposed_spouse: value === "true",
                }),
            })}

            {investor.pol_exposed_spouse &&
              FormHelper.renderTextArea({
                label:
                  "Please provide details: (i.e: full name, position held, dates of holding the position, name of organization)",
                placeholder: "Please describe",
                defaultValue: investor.pol_exposed_spouse_description,
                disabled: props.disabled,
                onChange: (e) =>
                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    pol_exposed_spouse_description: e.target.value ?? "",
                  }),
              })}
          </>
        );
      }

      case InvestorType.Entity: {
        return (
          <>
            {FormHelper.renderSelect({
              id: "pol_exposed_owner",
              label:
                "Are you or any of the beneficial owners, family members, or close business associates considered a politically exposed domestic person, a politically exposed foreign person, or a head of an international organization (as defined below)?",
              placeholder: "Select one",
              hint: "See footnotes",
              defaultValue: investor.pol_exposed_owner ? "true" : "false",
              disabled: props.disabled,
              options: [
                { i18Key: "Yes", value: "true" },
                { i18Key: "No", value: "false" },
              ],
              onValueChange: (value) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  pol_exposed_owner: value === "true",
                }),
            })}

            {investor.pol_exposed_owner &&
              FormHelper.renderTextArea({
                label:
                  "Please provide details: (i.e: full name, position held, dates of holding the position, name of organization)",
                placeholder: "Please describe",
                defaultValue: investor.pol_exposed_owner_description,
                disabled: props.disabled,
                onChange: (e) =>
                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    pol_exposed_owner_description: e.target.value ?? "",
                  }),
              })}
          </>
        );
      }

      default: {
        return;
      }
    }
  };

  return (
    <ErrorBoundary id="PoliticalExposureForm">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("Political Exposure")}
        {renderInputs()}
      </Flex>
    </ErrorBoundary>
  );
};

export default PoliticalExposureForm;
