import { Flex, Heading, Text } from "@radix-ui/themes";
import React, { ReactNode } from "react";

interface IProps {
  id: string;
  children: ReactNode;

  hideIntercom?: boolean;
}
interface IState {
  hasError: boolean;
  message?: string;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  /** state will update => next render will show the fallback UI */
  static getDerivedStateFromError(error: any) {
    console.debug(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(`ERROR BOUNDARY: ${error.message}`, errorInfo);
    this.setState({ message: error.message });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div className="p-4">
        <Flex direction="column" gap="4">
          <Heading>Uh-oh</Heading>

          <Text>
            It seems something isn't working quite right in{" "}
            <code>{this.props.id}</code>. Please contact support.
          </Text>

          {this.state.message && (
            <details>
              <summary>Error Message</summary>
              <code>{this.state.message}</code>
            </details>
          )}
        </Flex>
      </div>
    );
  }
}
