import {
  Box,
  Checkbox,
  Flex,
  Heading,
  RadioGroup,
  Select,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";
import { t } from "i18next";
import {
  CheckboxConfig,
  MultiCheckboxConfig,
  RadioConfig,
  SelectConfig,
} from "interfaces/forms/select";
import { TextAreaConfig, TextFieldConfig } from "interfaces/forms/text";

/** ensure that all forms render using the same styles */
export class FormHelper {
  private static renderHint = (i18key?: string) => {
    if (!i18key) {
      return;
    }

    return (
      <Text size="1" color="gray" align="right" style={{ display: "block" }}>
        {t(i18key)}
      </Text>
    );
  };

  private static renderTextLabel = (i18Key: string) => (
    <Text as="label" mb="2" style={{ display: "block" }}>
      {t(i18Key)}
    </Text>
  );

  static renderHeader = (i18Key: string) => {
    return <Heading>{t(i18Key)}</Heading>;
  };

  static renderTextField = (config: TextFieldConfig) => {
    return (
      <Box width="100%">
        {config.label && this.renderTextLabel(config.label)}

        <TextField.Root>
          {config.slot && <TextField.Slot>{config.slot}</TextField.Slot>}
          <TextField.Input
            variant="soft"
            disabled={config.disabled}
            placeholder={config.placeholder ? t(config.placeholder) : undefined}
            defaultValue={config.defaultValue}
            onChange={config.onChange}
          />
        </TextField.Root>

        {this.renderHint(config.hint)}
      </Box>
    );
  };

  static renderTextArea = (config: TextAreaConfig) => {
    return (
      <Box width="100%">
        {config.label && this.renderTextLabel(config.label)}

        <TextArea
          variant="soft"
          disabled={config.disabled}
          rows={config.rows ?? 10}
          placeholder={config.placeholder ? t(config.placeholder) : undefined}
          defaultValue={config.defaultValue}
          onChange={config.onChange}
        />

        {this.renderHint(config.hint)}
      </Box>
    );
  };

  static renderSelect = (config: SelectConfig) => {
    return (
      <Box width="100%">
        {config.label && this.renderTextLabel(config.label)}

        <Select.Root
          defaultValue={config.defaultValue}
          onValueChange={config.onValueChange}
          disabled={config.disabled}
        >
          <Select.Trigger
            variant="soft"
            style={{ width: "100%" }}
            placeholder={config.placeholder}
          />
          <Select.Content>
            {config.options.map((o, i) => (
              <Select.Item key={`${config.id}-option-${i}`} value={o.value}>
                {t(o.i18Key)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>

        {this.renderHint(config.hint)}
      </Box>
    );
  };

  static renderRadio = (config: RadioConfig) => {
    return (
      <Box width="100%">
        {config.label && this.renderTextLabel(config.label)}

        <RadioGroup.Root
          variant="soft"
          defaultValue={config.defaultValue}
          onValueChange={config.onValueChange}
          disabled={config.disabled}
        >
          <Flex gap="2" direction="column">
            {config.options.map((o, i) => (
              <Text key={`${config.id}-option-${i}`} as="label" size="2">
                <Flex gap="2">
                  <RadioGroup.Item value={o.value} />
                  {t(o.i18Key)}
                </Flex>
              </Text>
            ))}
          </Flex>
        </RadioGroup.Root>

        {this.renderHint(config.hint)}
      </Box>
    );
  };

  static renderCheckbox = (config: CheckboxConfig) => {
    return (
      <Box width="100%">
        <Text>
          <Flex gap="2">
            <Checkbox
              variant="soft"
              disabled={config.disabled}
              defaultChecked={config.defaultChecked}
              onCheckedChange={(checked) => config.onCheckedChange(checked)}
            />
            {config.label && this.renderTextLabel(config.label)}
          </Flex>
        </Text>

        {this.renderHint(config.hint)}
      </Box>
    );
  };

  static renderMultiCheckbox = (config: MultiCheckboxConfig) => {
    return (
      <Box width="100%">
        <Flex gap="2" direction="column">
          {config.label && this.renderTextLabel(config.label)}

          {config.options.map((o, i) => (
            <Text key={`${config.id}-option-${i}`} as="label" size="2">
              <Flex gap="2">
                <Checkbox
                  variant="soft"
                  disabled={config.disabled}
                  defaultChecked={config.defaultValues?.includes(o.value)}
                  onCheckedChange={(checked) =>
                    config.onCheckedChange(checked, o.value)
                  }
                />
                {t(o.i18Key)}
              </Flex>
            </Text>
          ))}
        </Flex>

        {this.renderHint(config.hint)}
      </Box>
    );
  };
}
