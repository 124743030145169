import { Button, Flex, Heading, Table, Text } from "@radix-ui/themes";
import { MiscHelper } from "classes/misc.helper";
import { ErrorBoundary } from "components/common/error-boundary";
import { lightFormat, parseISO } from "date-fns";
import { InvestorType } from "enums/common/investors";
import { Investor } from "interfaces/common/investor";
import React from "react";
import { InvestorsService } from "services/investors";
import InvestorDetailsDialog from "./investor-details.dialog";

export const WILDCARD_TENANT = "*";

interface Props {
  tenant: string;
}

interface State {
  investors: Partial<Investor>[];
}

export class InvestorsListing extends React.Component<Props, State> {
  private init = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      investors: [],
    };

    this.reload = this.reload.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;
    this.reload();
  }

  private reload() {
    InvestorsService.getInstance()
      .getByTenant(this.props.tenant)
      .then((investors) => this.setState({ investors: investors }));
  }

  render(): React.ReactNode {
    return (
      <ErrorBoundary id="InvestorsListing">
        <Flex direction="column" gap="4">
          <Flex justify="between">
            <Heading>Investors</Heading>

            <Flex gap="2">
              <Button
                variant="soft"
                disabled={this.state.investors.length === 0}
                onClick={async () => {
                  const csvString =
                    await InvestorsService.getInstance().exportByTenant(
                      this.props.tenant
                    );

                  const blob = new Blob([csvString], { type: "text/csv" });
                  MiscHelper.saveAs(
                    blob,
                    `investors-export-${lightFormat(
                      new Date(),
                      "yyyy-MM-dd"
                    )}.csv`
                  );
                }}
              >
                Export CSV
              </Button>

              {this.props.tenant === WILDCARD_TENANT && (
                <Button
                  variant="soft"
                  disabled={this.state.investors.length === 0}
                  onClick={async () => {
                    const jsonString = JSON.stringify(
                      this.state.investors,
                      (key: string, value: any) => {
                        if (["_keywords", "_tags", "_version"].includes(key)) {
                          return undefined;
                        }

                        return value;
                      },
                      2
                    );

                    const blob = new Blob([jsonString], { type: "text/json" });
                    MiscHelper.saveAs(
                      blob,
                      `investors-export-${lightFormat(
                        new Date(),
                        "yyyy-MM-dd"
                      )}.json`
                    );
                  }}
                >
                  Export JSON
                </Button>
              )}
            </Flex>
          </Flex>

          {this.props.tenant !== WILDCARD_TENANT && (
            <Text>
              We found {this.state.investors.length} investors for the tenant
              associated with your email domain.
            </Text>
          )}

          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Created</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Identifier</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Type</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Details</Table.ColumnHeaderCell>
                {this.props.tenant === WILDCARD_TENANT && (
                  <Table.ColumnHeaderCell>Tenant</Table.ColumnHeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.investors.map((m, i) => {
                const typeCell = <Table.Cell>{m.type}</Table.Cell>;

                const createdCell = (
                  <Table.Cell>
                    {m._created
                      ? lightFormat(parseISO(m._created), "yyyy-MM-dd")
                      : "???"}
                  </Table.Cell>
                );

                const detailsCell = (
                  <Table.Cell>
                    <InvestorDetailsDialog investor={m} />
                  </Table.Cell>
                );

                const tenantCell = <Table.Cell>{m.tenant}</Table.Cell>;

                switch (m.type) {
                  case InvestorType.Individual: {
                    return (
                      <Table.Row key={`individual-${i}`}>
                        {createdCell}
                        <Table.Cell>
                          <a href={`mailto:${m.individual_email}`}>
                            {m.individual_email}
                          </a>
                        </Table.Cell>
                        <Table.Cell>{m.individual_name}</Table.Cell>
                        {typeCell}
                        {detailsCell}
                        {this.props.tenant === WILDCARD_TENANT && tenantCell}
                      </Table.Row>
                    );
                  }

                  case InvestorType.Entity:
                  default: {
                    return (
                      <Table.Row key={`entity-${i}`}>
                        {createdCell}
                        <Table.Cell>
                          <a href={`mailto:${m.entity_email}`}>
                            {m.entity_email}
                          </a>
                        </Table.Cell>
                        <Table.Cell>{m.entity_name}</Table.Cell>
                        {typeCell}
                        {detailsCell}
                        {this.props.tenant === WILDCARD_TENANT && tenantCell}
                      </Table.Row>
                    );
                  }
                }
              })}
            </Table.Body>
          </Table.Root>
        </Flex>
      </ErrorBoundary>
    );
  }
}
