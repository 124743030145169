import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@radix-ui/themes";

const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant="soft"
      onClick={() =>
        loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })
      }
    >
      Sign Up
    </Button>
  );
};

export default SignUpButton;
