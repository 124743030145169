import { Flex, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import TaxJurisdictionInputs from "components/common/tax-jurisdiction";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const IndividualRegulatoryDetails = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="IndividualRegulatoryDetails">
      <Flex direction="column" gap="4">
        <TaxJurisdictionInputs {...props} />

        {FormHelper.renderRadio({
          id: "individual_registered",
          label:
            "Are you registered or required to be registered under the securities legislation of any province or territory of Canada?",
          defaultValue: investor.individual_registered ? "true" : "false",
          disabled: props.disabled,
          options: [
            { i18Key: "Yes", value: "true" },
            { i18Key: "No", value: "false" },
          ],
          onValueChange: (value) => {
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_registered: value === "true",
            });
          },
        })}

        {FormHelper.renderRadio({
          id: "individual_registered",
          label:
            "Are you or your spouse an insider of any reporting issuer, or other issuer, whose securities are publicly traded (as those terms are defined under applicable securities law)?",
          defaultValue: investor.individual_insider ? "true" : "false",
          disabled: props.disabled,
          options: [
            { i18Key: "Yes", value: "true" },
            { i18Key: "No", value: "false" },
          ],
          onValueChange: (value) => {
            props.cookiesCx.setCookie(CookieKey.Investor, {
              individual_insider: value === "true",
            });
          },
        })}

        {investor.individual_insider && (
          <>
            <Text>Please provide the following insider details:</Text>

            {FormHelper.renderTextField({
              placeholder: "Insider name",
              defaultValue: investor.individual_insider_name,
              disabled: props.disabled,
              onChange: (e) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  individual_insider_name: e.target.value ?? "",
                }),
            })}

            {FormHelper.renderTextField({
              placeholder: "Issuer name",
              defaultValue: investor.individual_insider_issuer,
              disabled: props.disabled,
              onChange: (e) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  individual_insider_issuer: e.target.value ?? "",
                }),
            })}

            {FormHelper.renderTextField({
              placeholder: "Position",
              defaultValue: investor.individual_insider_position,
              disabled: props.disabled,
              onChange: (e) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  individual_insider_position: e.target.value ?? "",
                }),
            })}
          </>
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export default IndividualRegulatoryDetails;
