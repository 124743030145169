import { Flex, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { showToast } from "components/common/toast-listener";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { InvestorType, hasSpouse } from "enums/common/investors";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const KycDetails = (props: Props) => {
  const flagInvalidNumber = () =>
    showToast({
      title: "Invalid Number",
      description: "Please check your input and try again.",
    });

  const investor = props.cookiesCx.investor;

  const showSpouse =
    investor.type === InvestorType.Individual &&
    hasSpouse(investor.individual_marital_status);

  const localDisabled =
    props.disabled || investor.waive_suitability_via_permitted_client;

  return (
    <ErrorBoundary id="KycDetails">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("KYC Details")}

        {FormHelper.renderTextField({
          slot: <>$</>,
          label: "Net Worth",
          defaultValue: investor.net_worth?.toString(),
          disabled: localDisabled,
          onChange: (e) => {
            const maybeNum = parseFloat(e.target.value);

            if (isNaN(maybeNum)) {
              flagInvalidNumber();
              return;
            }

            props.cookiesCx.setCookie(CookieKey.Investor, {
              net_worth: maybeNum,
            });
          },
        })}

        {showSpouse &&
          FormHelper.renderTextField({
            slot: <>$</>,
            label: "Spouse/Partner's Net Worth",
            defaultValue: investor.spouse_net_worth?.toString(),
            disabled: localDisabled,
            onChange: (e) => {
              const maybeNum = parseFloat(e.target.value);

              if (isNaN(maybeNum)) {
                flagInvalidNumber();
                return;
              }

              props.cookiesCx.setCookie(CookieKey.Investor, {
                spouse_net_worth: maybeNum,
              });
            },
          })}

        {FormHelper.renderTextField({
          slot: <>$</>,
          label: "Net Financial Assets",
          defaultValue: investor.net_financial_assets?.toString(),
          disabled: localDisabled,
          onChange: (e) => {
            const maybeNum = parseFloat(e.target.value);

            if (isNaN(maybeNum)) {
              flagInvalidNumber();
              return;
            }

            props.cookiesCx.setCookie(CookieKey.Investor, {
              net_financial_assets: maybeNum,
            });
          },
        })}

        {showSpouse &&
          FormHelper.renderTextField({
            slot: <>$</>,
            label: "Spouse/Partner's Net Financial Assets",
            defaultValue: investor.spouse_net_financial_assets?.toString(),
            disabled: localDisabled,
            onChange: (e) => {
              const maybeNum = parseFloat(e.target.value);

              if (isNaN(maybeNum)) {
                flagInvalidNumber();
                return;
              }

              props.cookiesCx.setCookie(CookieKey.Investor, {
                spouse_net_financial_assets: maybeNum,
              });
            },
          })}

        {FormHelper.renderTextField({
          slot: <>$</>,
          label: "Annual Income",
          defaultValue: investor.annual_income?.toString(),
          disabled: localDisabled,
          onChange: (e) => {
            const maybeNum = parseFloat(e.target.value);

            if (isNaN(maybeNum)) {
              flagInvalidNumber();
              return;
            }

            props.cookiesCx.setCookie(CookieKey.Investor, {
              annual_income: maybeNum,
            });
          },
        })}

        {showSpouse &&
          FormHelper.renderTextField({
            slot: <>$</>,
            label: "Spouse/Partner's Annual Income",
            defaultValue: investor.spouse_annual_income?.toString(),
            disabled: localDisabled,
            onChange: (e) => {
              const maybeNum = parseFloat(e.target.value);

              if (isNaN(maybeNum)) {
                flagInvalidNumber();
                return;
              }

              props.cookiesCx.setCookie(CookieKey.Investor, {
                spouse_annual_income: maybeNum,
              });
            },
          })}

        {FormHelper.renderTextField({
          slot: <>$</>,
          label: "Liquidity Needs",
          defaultValue: investor.liquidity_needs?.toString(),
          hint: "See footnotes",
          disabled: localDisabled,
          onChange: (e) => {
            const maybeNum = parseFloat(e.target.value);

            if (isNaN(maybeNum)) {
              flagInvalidNumber();
              return;
            }

            props.cookiesCx.setCookie(CookieKey.Investor, {
              liquidity_needs: maybeNum,
            });
          },
        })}

        {FormHelper.renderSelect({
          id: "investment_objective",
          label: "Investment Objective",
          placeholder: "Select one",
          defaultValue: investor.investment_objective,
          disabled: localDisabled,
          options: ["Capital Growth", "Preservation", "Income", "Other"].map(
            (o) => ({
              i18Key: o,
              value: o,
            })
          ),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              investment_objective: value,
            }),
        })}

        {FormHelper.renderSelect({
          id: "time_horizon",
          label: "Time Horizon",
          placeholder: "Select one",
          defaultValue: investor.time_horizon,
          disabled: localDisabled,
          options: [
            "1-3 years",
            "5 years",
            "10 years",
            "More than 10 years",
          ].map((o) => ({
            i18Key: o,
            value: o,
          })),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              time_horizon: value,
            }),
        })}

        {FormHelper.renderSelect({
          id: "transaction_freq",
          label: "Transaction Frequency",
          placeholder: "Select one",
          defaultValue: investor.transaction_freq,
          disabled: localDisabled,
          options: [
            "1-5 per month",
            "Up to 10 per month",
            "More than 10 per month",
          ].map((o) => ({
            i18Key: o,
            value: o,
          })),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              transaction_freq: value,
            }),
        })}

        {FormHelper.renderSelect({
          id: "investment_knowledge",
          label: "Investment Knowledge",
          placeholder: "Select one",
          hint: "See footnotes",
          defaultValue: investor.investment_knowledge,
          disabled: localDisabled,
          options: ["Expert", "High", "Moderate", "Low"].map((o) => ({
            i18Key: o,
            value: o,
          })),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              investment_knowledge: value,
            }),
        })}

        {FormHelper.renderSelect({
          id: "risk_profile",
          label: "Risk Profile",
          placeholder: "Select one",
          hint: "See footnotes",
          defaultValue: investor.risk_profile,
          disabled: localDisabled,
          options: ["High", "Medium", "Low"].map((o) => ({
            i18Key: o,
            value: o,
          })),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              risk_profile: value,
            }),
        })}

        {investor.type === InvestorType.Individual &&
          FormHelper.renderSelect({
            id: "individual_borrowed_funds",
            label: "Are borrowed funds being used to invest?",
            placeholder: "Select one",
            defaultValue: investor.individual_borrowed_funds ? "true" : "false",
            disabled: localDisabled,
            options: [
              { i18Key: "Yes", value: "true" },
              { i18Key: "No", value: "false" },
            ],
            onValueChange: (value) =>
              props.cookiesCx.setCookie(CookieKey.Investor, {
                individual_borrowed_funds: value === "true",
              }),
          })}

        {investor.type === InvestorType.Individual && (
          <>
            {FormHelper.renderTextField({
              slot: <>%</>,
              label: `What proportion of your net financial assets is invested in exempt market products?`,
              defaultValue:
                investor.individual_financial_assets_percent?.toString(),
              placeholder: "0 to 100",
              disabled: localDisabled,
              onChange: (e) => {
                const maybeNum = parseFloat(e.target.value);

                if (isNaN(maybeNum)) {
                  flagInvalidNumber();
                  return;
                }

                props.cookiesCx.setCookie(CookieKey.Investor, {
                  individual_financial_assets_percent: maybeNum,
                });
              },
            })}

            <Text>
              What proportion of your net financial assets is invested in each
              asset class as a percentage?
            </Text>

            <Flex gap="2">
              {FormHelper.renderTextField({
                slot: <>%</>,
                label: "Cash",
                defaultValue: investor.individual_cash_percent?.toString(),
                placeholder: "0 to 100",
                disabled: localDisabled,
                onChange: (e) => {
                  const maybeNum = parseFloat(e.target.value);

                  if (isNaN(maybeNum)) {
                    flagInvalidNumber();
                    return;
                  }

                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    individual_cash_percent: maybeNum,
                  });
                },
              })}

              {FormHelper.renderTextField({
                slot: <>%</>,
                label: "Stocks",
                defaultValue: investor.individual_stocks_percent?.toString(),
                placeholder: "0 to 100",
                disabled: localDisabled,
                onChange: (e) => {
                  const maybeNum = parseFloat(e.target.value);

                  if (isNaN(maybeNum)) {
                    flagInvalidNumber();
                    return;
                  }

                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    individual_stocks_percent: maybeNum,
                  });
                },
              })}
            </Flex>

            <Flex gap="2">
              {FormHelper.renderTextField({
                slot: <>%</>,
                label: "Bonds",
                defaultValue: investor.individual_bonds_percent?.toString(),
                placeholder: "0 to 100",
                disabled: localDisabled,
                onChange: (e) => {
                  const maybeNum = parseFloat(e.target.value);

                  if (isNaN(maybeNum)) {
                    flagInvalidNumber();
                    return;
                  }

                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    individual_bonds_percent: maybeNum,
                  });
                },
              })}

              {FormHelper.renderTextField({
                slot: <>%</>,
                label: "Other",
                defaultValue: investor.individual_other_percent?.toString(),
                placeholder: "0 to 100",
                disabled: localDisabled,
                onChange: (e) => {
                  const maybeNum = parseFloat(e.target.value);

                  if (isNaN(maybeNum)) {
                    flagInvalidNumber();
                    return;
                  }

                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    individual_other_percent: maybeNum,
                  });
                },
              })}
            </Flex>
          </>
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export default KycDetails;
