import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import TaxJurisdictionInputs from "components/common/tax-jurisdiction";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { CAN_PROVINCES, LegalEntityType } from "enums/common/investors";
import { FormHelper } from "../form.helper";

const ENABLE_CRA_FIELD = false;
const ENABLE_DIRECTORS_FIELD = false;

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const EntityRegulatoryDetails = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="EntityRegulatoryDetails">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("Regulatory Details")}

        {FormHelper.renderSelect({
          id: "legal_entity_type",
          label: "Legal entity type",
          placeholder: "Select one",
          defaultValue: investor.legal_entity_type,
          disabled: props.disabled,
          options: Object.values(LegalEntityType).map((o) => ({
            i18Key: o,
            value: o,
          })),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              legal_entity_type: value,
            }),
        })}

        {FormHelper.renderTextField({
          label: "Name of regulatory or licensing body",
          defaultValue: investor.reg_body,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              reg_body: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          label: "Registration or licensing number",
          defaultValue: investor.reg_number,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              reg_number: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderSelect({
          id: "reg_jurisdiction",
          label: "Registered jurisdiction",
          placeholder: "Select one",
          defaultValue: investor.reg_jurisdiction,
          disabled: props.disabled,
          options: [...CAN_PROVINCES.map((p) => p.name), "Federal"].map(
            (o) => ({
              i18Key: o,
              value: o,
            })
          ),
          onValueChange: (value) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              reg_jurisdiction: value,
            }),
        })}

        <TaxJurisdictionInputs {...props} />

        {ENABLE_CRA_FIELD &&
          FormHelper.renderTextField({
            label: "CRA business ID #",
            defaultValue: investor.cra_business_id,
            disabled: props.disabled,
            onChange: (e) =>
              props.cookiesCx.setCookie(CookieKey.Investor, {
                cra_business_id: e.target.value ?? "",
              }),
          })}

        {ENABLE_DIRECTORS_FIELD &&
          FormHelper.renderTextArea({
            placeholder:
              "List of Directors (if not identified in the constating documents)",
            defaultValue: investor.directors,
            disabled: props.disabled,
            onChange: (e) =>
              props.cookiesCx.setCookie(CookieKey.Investor, {
                directors: e.target.value ?? "",
              }),
          })}
      </Flex>
    </ErrorBoundary>
  );
};

export default EntityRegulatoryDetails;
