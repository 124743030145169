import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookiesInterface } from "contexts/cookies";
import { InvestorType } from "enums/common/investors";
import AccreditedInvestor from "./accredited-investor";
import KycDetails from "./kyc-details";
import PermittedClient from "./permitted-client";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const SuitabilityForm = (props: Props) => {
  const investor = props.cookiesCx.investor;

  const entitySkippingSuitability =
    investor.type === InvestorType.Entity &&
    investor.net_assets_over_25MM &&
    investor.waive_suitability_via_assets;

  const showPermittedClient = !entitySkippingSuitability;

  const showAccreditedInvestor = !(
    entitySkippingSuitability || investor.waive_suitability_via_permitted_client
  );

  const showKyc = !(
    entitySkippingSuitability || investor.waive_suitability_via_permitted_client
  );

  return (
    <ErrorBoundary id="SuitabilityForm">
      <Flex direction="column" gap="4">
        {showPermittedClient && <PermittedClient {...props} />}
        {showAccreditedInvestor && <AccreditedInvestor {...props} />}
        {showKyc && <KycDetails {...props} />}
      </Flex>
    </ErrorBoundary>
  );
};

export default SuitabilityForm;
