import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookiesInterface } from "contexts/cookies";
import IndividualDetails from "./details";
import IndividualRegulatoryDetails from "./regulatory-details";
import RelationshipDisclosure from "./relationship-disclosure";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const IndividualForm = (props: Props) => {
  return (
    <ErrorBoundary id="IndividualForm">
      <Flex direction="column" gap="4">
        <IndividualDetails {...props} />
        <IndividualRegulatoryDetails {...props} />
        <RelationshipDisclosure {...props} />
      </Flex>
    </ErrorBoundary>
  );
};

export default IndividualForm;
