import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "../form.helper";

const ACCREDITED_INVESTOR_REASONS = [
  `(a) except in Ontario, a Canadian financial institution, or a Schedule III bank;`,
  `(b) except in Ontario, the Business Development Bank of Canada incorporated under the Business Development Bank of Canada Act (Canada);`,
  `(c) except in Ontario, a subsidiary of any person referred to in paragraphs (a) or (b), if the person owns all of the voting securities of the subsidiary, except the voting securities required by law to be owned by directors of that subsidiary;`,
  `(d) except in Ontario, a person registered under the securities legislation of a jurisdiction of Canada as an adviser or dealer;`,
  `(e) an individual registered under the securities legislation of a jurisdiction of Canada as a representative of a person referred to in paragraph (d);`,
  `(e.1) an individual formerly registered under the securities legislation of a jurisdiction of Canada, other than an individual formerly registered solely as a representative of a limited market dealer under one or both of the Securities Act (Ontario) or the Securities Act (Newfoundland and Labrador);`,
  `(f) except in Ontario, the Government of Canada or a jurisdiction of Canada, or any crown corporation, agency or wholly owned entity of the Government of Canada or a jurisdiction of Canada;`,
  `(g) except in Ontario, a municipality, public board or commission in Canada and a metropolitan community, school board, the Comité de gestion de la taxe scolaire de l'île de Montréal or an intermunicipal management board in Québec;`,
  `(h) except in Ontario, any national, federal, state, provincial, territorial or municipal government of or in any foreign jurisdiction, or any agency of that government;`,
  `(i) except in Ontario, a pension fund that is regulated by the Office of the Superintendent of Financial Institutions (Canada), a pension commission or similar regulatory authority of a jurisdiction of Canada;`,
  `(j) an individual who, either alone or with a spouse, beneficially owns financial assets having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $1 000 000;`,
  `(j.1) an individual who beneficially owns financial assets having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $5 000 000;`,
  `(k) an individual whose net income before taxes exceeded $200 000 in each of the 2 most recent calendar years or whose net income before taxes combined with that of a spouse exceeded $300 000 in each of the 2 most recent calendar years and who, in either case, reasonably expects to exceed that net income level in the current calendar year;`,
  `(l) an individual who, either alone or with a spouse, has net assets of at least $5 000 000;`,
  `(m) a person, other than an individual or investment fund, that has net assets of at least $5 000 000 as shown on its most recently prepared financial statements;`,
  `(n) an investment fund that distributes or has distributed its securities only to`,
  `(i) a person that is or was an accredited investor at the time of the distribution, (ii) a person that acquires or acquired securities in the circumstances referred to in sections 2.10 [Minimum amount investment], or 2.19 [Additional investment in investment funds], or (iii) a person described in paragraph (i) or (ii) that acquires or acquired securities under section 2.18 [Investment fund reinvestment];`,
  `(o) an investment fund that distributes or has distributed securities under a prospectus in a jurisdiction of Canada for which the regulator or, in Québec, the securities regulatory authority, has issued a receipt;`,
  `(p) a trust company or trust corporation registered or authorized to carry on business under the Trust and Loan Companies Act (Canada) or under comparable legislation in a jurisdiction of Canada or a foreign jurisdiction, acting on behalf of a fully managed account managed by the trust company or trust corporation, as the case may be;`,
  `(q) a person acting on behalf of a fully managed account managed by that person, if that person is registered or authorized to carry on business as an adviser or the equivalent under the securities legislation of a jurisdiction of Canada or a foreign jurisdiction;`,
  `(r) a registered charity under the Income Tax Act (Canada) that, in regard to the trade, has obtained advice from an eligibility adviser or an adviser registered under the securities legislation of the jurisdiction of the registered charity to give advice on the securities being traded;`,
  `(s) an entity organized in a foreign jurisdiction that is analogous to any of the entities referred to in paragraphs (a) to (d) or paragraph (i) in form and function;`,
  `(t) a person in respect of which all of the owners of interests, direct, indirect or beneficial, except the voting securities required by law to be owned by directors, are persons that are accredited investors;`,
  `(u) an investment fund that is advised by a person registered as an adviser or a person that is exempt from registration as an adviser;`,
  `(v) a person that is recognized or designated by the securities regulatory authority or, except in Ontario and Québec, the regulator as an accredited investor; or`,
  `(w) a trust established by an accredited investor for the benefit of the accredited investor's family members of which a majority of the trustees are accredited investors and all of the beneficiaries are the accredited investor's spouse, a former spouse of the accredited investor or a parent, grandparent, brother, sister, child or grandchild of that accredited investor, of that accredited investor's spouse or of that accredited investor's former spouse;`,
];

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const AccreditedInvestor = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="AccreditedInvestor">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("Accredited Investor")}

        {FormHelper.renderMultiCheckbox({
          id: "accredited_investor",
          label:
            "Do you qualify as an Accredited Investor as per below? (tick all the boxes that apply)",
          defaultValues: investor.accredited_investor,
          disabled: props.disabled,
          options: ACCREDITED_INVESTOR_REASONS.map((o) => ({
            i18Key: o,
            value: o,
          })),
          onCheckedChange: (checked, value) => {
            const currentArray = investor.accredited_investor ?? [];

            switch (checked) {
              // checked
              case "true":
              case true: {
                if (currentArray.includes(value)) {
                  return;
                }

                props.cookiesCx.setCookie(CookieKey.Investor, {
                  accredited_investor: [...currentArray, value],
                });
                return;
              }

              // unchecked or unknown
              default: {
                if (!currentArray.includes(value)) {
                  return;
                }

                props.cookiesCx.setCookie(CookieKey.Investor, {
                  accredited_investor: currentArray.filter((v) => v !== value),
                });
                return;
              }
            }
          },
        })}
      </Flex>
    </ErrorBoundary>
  );
};

export default AccreditedInvestor;
