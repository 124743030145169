import { Box, Container, Flex } from "@radix-ui/themes";
import LoginButton from "components/auth0/login-button";
import LogoutButton from "components/auth0/logout-button";
import SignUpButton from "components/auth0/signup-button";
import { ErrorBoundary } from "components/common/error-boundary";
import { IAuthContext } from "contexts/auth";
import { Tenant } from "enums/tenants";

const getTenantLogo = (tenant?: Tenant) => {
  switch (tenant) {
    case Tenant.Homeslice: {
      return "/tenant-logos/homeslice-dark.svg";
    }

    default:
      return "/logo_dark.svg";
  }
};

interface Props {
  authCx?: IAuthContext;
  tenant?: Tenant;
}

const Header = (props: Props) => {
  return (
    <ErrorBoundary id="Header">
      <Container size="3">
        <Flex pt="4" pb="4">
          <Box>
            <img style={{ height: "32px" }} src={getTenantLogo(props.tenant)} />
          </Box>

          <Box grow="1">&nbsp;</Box>

          {props.authCx && (
            <Flex direction="column" justify="center">
              <Flex gap="2">
                {props.authCx.user ? <LogoutButton /> : <LoginButton />}
                {!props.authCx.user && <SignUpButton />}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Container>
    </ErrorBoundary>
  );
};

export default Header;
