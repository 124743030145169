import { Callout, Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "../form.helper";

const PERMITTED_CLIENT_REASONS = [
  `(a) a Canadian financial institution or a Schedule III bank;`,
  `(b) the Business Development Bank of Canada incorporated under the Business Development Bank of Canada Act (Canada);`,
  `(c) a subsidiary of any person or company referred to in paragraph (a) or (b), if the person or company owns all of the voting securities of the subsidiary, except the voting securities required by law to be owned by directors of the subsidiary;`,
  `(d) a person or company registered under the securities legislation of a jurisdiction of Canada as an adviser, investment dealer, mutual fund dealer or exempt market dealer;`,
  `(e) a pension fund that is regulated by either the federal Office of the Superintendent of Financial Institutions or a pension commission or similar regulatory authority of a jurisdiction of Canada or a wholly-owned subsidiary of such a pension fund;`,
  `(f) an entity organized in a foreign jurisdiction that is analogous to any of the entities referred to in paragraphs (a) to (e);`,
  `(g) the Government of Canada or a jurisdiction of Canada, or any Crown corporation, agency or wholly-owned entity of the Government of Canada or a jurisdiction of Canada;`,
  `(h) any national, federal, state, provincial, territorial or municipal government of or in any foreign jurisdiction, or any agency of that government;`,
  `(i) a municipality, public board or commission in Canada and a metropolitan community, school board, the Comité de gestion de la taxe scolaire de l'île de Montréal or an intermunicipal management board in Québec;`,
  `(j) a trust company or trust corporation registered or authorized to carry on business under the Trust and Loan Companies Act (Canada) or under comparable legislation in a jurisdiction of Canada or a foreign jurisdiction, acting on behalf of a managed account managed by the trust company or trust corporation, as the case may be;`,
  `(k) a person or company acting on behalf of a managed account managed by the person or company, if the person or company is registered or authorized to carry on business as an adviser or the equivalent under the securities legislation of a jurisdiction of Canada or a foreign jurisdiction;`,
  `(l) an investment fund if one or both of the following apply: (i) the fund is managed by a person or company registered as an investment fund manager under the securities legislation of a jurisdiction of Canada; (ii) the fund is advised by a person or company authorized to act as an adviser under the securities legislation of a jurisdiction of Canada;`,
  `(m) in respect of a dealer, a registered charity under the Income Tax Act (Canada) that obtains advice on the securities to be traded from an eligibility adviser, as defined in section 1.1 of National Instrument 45-106 Prospectus Exemptions, or an adviser registered under the securities legislation of the jurisdiction of the registered charity;`,
  `(n) in respect of an adviser, a registered charity under the Income Tax Act (Canada) that is advised by an eligibility adviser, as defined in section 1.1 of National Instrument 45-106 Prospectus Exemptions, or an adviser registered under the securities legislation of the jurisdiction of the registered charity;`,
  `(o) an individual who beneficially owns financial assets, as defined in section 1.1 of National Instrument 45-106 Prospectus Exemptions, having an aggregate realizable value that, before taxes but net of any related liabilities, exceeds $5 million;`,
  `(p) a person or company that is entirely owned by an individual or individuals referred to in paragraph (o), who holds the beneficial ownership interest in the person or company directly or through a trust, the trustee of which is a trust company or trust corporation registered or authorized to carry on business under the Trust and Loan Companies Act (Canada) or under comparable legislation in a jurisdiction of Canada or a foreign jurisdiction;`,
  `(q) a person or company, other than an individual or an investment fund, that has net assets of at least $25 million as shown on its most recently prepared financial statements;`,
  `(r) a person or company that distributes securities of its own issue in Canada only to persons or companies referred to in paragraphs (a) to (q);`,
];

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const PermittedClient = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="PermittedClient">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("Permitted Client")}

        {FormHelper.renderMultiCheckbox({
          id: "permitted_client",
          label:
            "Do you qualify as a Non-Individual Permitted Client as per below? (tick all the boxes that apply)",
          defaultValues: investor.permitted_client,
          disabled: props.disabled,
          options: PERMITTED_CLIENT_REASONS.map((o) => ({
            i18Key: o,
            value: o,
          })),
          onCheckedChange: (checked, value) => {
            const current = investor.permitted_client ?? [];

            switch (checked) {
              // checked
              case "true":
              case true: {
                if (current.includes(value)) {
                  return;
                }

                props.cookiesCx.setCookie(CookieKey.Investor, {
                  permitted_client: [...current, value],
                });
                return;
              }

              // unchecked or unknown
              default: {
                if (!current.includes(value)) {
                  return;
                }

                const nextValue = current.filter((v) => v !== value);

                props.cookiesCx.setCookie(CookieKey.Investor, {
                  permitted_client: nextValue,
                  waive_suitability_via_permitted_client:
                    nextValue.length === 0
                      ? false
                      : investor.waive_suitability_via_permitted_client,
                });
                return;
              }
            }
          },
        })}

        {investor.permitted_client && investor.permitted_client.length > 0 && (
          <Callout.Root>
            <Callout.Text>
              {FormHelper.renderCheckbox({
                id: "waive_suitability_via_permitted_client",
                label:
                  "As a Permitted Client, I do not wish for BridgeMe Securities Inc. to advise on the suitability of the investment.",
                defaultChecked:
                  !!investor.waive_suitability_via_permitted_client,
                disabled: props.disabled,
                onCheckedChange: (value) =>
                  props.cookiesCx.setCookie(CookieKey.Investor, {
                    waive_suitability_via_permitted_client:
                      value === true || value === "true",
                  }),
              })}
            </Callout.Text>
          </Callout.Root>
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export default PermittedClient;
