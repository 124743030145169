import { EnvIdentifier } from "enums/env";

export const RELEASE_VERSION = "1.0.0";

export interface EnvConfig {
  release: string;

  identifier: EnvIdentifier;
  maintenance: boolean;
  strict: boolean;

  production: boolean;

  /** set to false to suppress all console.debug messages */
  debug_log: boolean;

  /** additional configuration details go here */
  self_url: string;
  server_url: string;
}
