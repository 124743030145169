import { User, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ErrorBoundary } from "components/common/error-boundary";
import env from "config";
import { FC, ReactNode, createContext, useEffect, useState } from "react";
import { MainService } from "services/main";

export interface IAuthContext {
  loading?: boolean;
  token?: string;
  user?: User;
}

const DEFAULT: IAuthContext = {};

/** for manipulating anything that needs to load from or save to a cookie */
export const AuthContext = createContext<IAuthContext>(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const AuthProvider: FC<IProps> = (props) => {
  const [_token, _setToken] = useState<string | undefined>();

  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  const state: IAuthContext = {
    token: _token,
    loading: isLoading,
    user: user,
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    // get access token
    getAccessTokenSilently({
      authorizationParams: {
        audience: env.auth0.audience,
        scope: "read:current_user",
      },
    })
      .then((token) => {
        axios.defaults.headers.common["Authorization"] = token
          ? `Bearer ${token}`
          : undefined;

        axios.defaults.headers.common["x-user"] = token
          ? JSON.stringify(user)
          : undefined;

        MainService.getInstance()
          .uptime()
          .then((result) => console.debug(result))
          .catch((error) => console.error(error));

        _setToken(token);
      })
      .catch((error) => {
        console.error(error);
        _setToken(undefined);
      });
  }, [getAccessTokenSilently, user]);

  return (
    <ErrorBoundary id="AuthContext">
      <AuthContext.Provider value={state}>
        {props.children}
      </AuthContext.Provider>
    </ErrorBoundary>
  );
};
