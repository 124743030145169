export enum InvestorType {
  Entity = "Entity",
  Individual = "Individual",
}

export enum MaritalStatus {
  Single = "Single",
  CommonLaw = "Common-Law",
  Married = "Married",
  Divorced = "Divorced",
}

export const hasSpouse = (status?: MaritalStatus) => {
  switch (status) {
    case MaritalStatus.CommonLaw:
    case MaritalStatus.Married:
      return true;

    default: {
      return false;
    }
  }
};

export enum TaxJurisdiction {
  Canada = "Canada",
  USA = "USA",
  Other = "Other",
}

export const CAN_PROVINCES: {
  code: string;
  name: string;
  isTerritory?: boolean;
}[] = [
  {
    code: "AB",
    name: "Alberta",
  },
  {
    code: "BC",
    name: "British Columbia",
  },
  {
    code: "MB",
    name: "Manitoba",
  },
  {
    code: "NB",
    name: "New Brunswick",
  },
  {
    code: "NL",
    name: "Newfoundland and Labrador",
  },
  {
    code: "NS",
    name: "Nova Scotia",
  },
  {
    code: "NT",
    name: "Northwest Territories",
    isTerritory: true,
  },
  {
    code: "NU",
    name: "Nunavut",
    isTerritory: true,
  },
  {
    code: "ON",
    name: "Ontario",
  },
  {
    code: "PE",
    name: "Prince Edward Island",
  },
  {
    code: "QC",
    name: "Québec",
  },
  {
    code: "SK",
    name: "Saskatchewan",
  },
  {
    code: "YT",
    name: "Yukon",
    isTerritory: true,
  },
];

export enum LegalEntityType {
  SoleProprietor = "Sole Proprietor",
  Partnership = "Partnership",
  Corporation = "Corporation",
  Trust = "Trust",
}
