import {
  AlertDialog,
  Button,
  Code,
  Flex,
  Heading,
  Link,
  Separator,
  Text,
  Tooltip,
} from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import ReCAPTCHAWrapper from "components/common/recaptcha";
import { showToast } from "components/common/toast-listener";
import env from "config";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { InvestorType, TaxJurisdiction } from "enums/common/investors";
import { Tenant } from "enums/tenants";
import { t } from "i18next";
import { Investor } from "interfaces/common/investor";
import React from "react";
import { InvestorsService } from "services/investors";
import EntityForm from "./entity-forms";
import Footnotes from "./footnotes";
import { FormHelper } from "./form.helper";
import IndividualForm from "./individual-forms";
import PoliticalExposureForm from "./political-exposure-forms";
import SuitabilityForm from "./suitability-forms";
import ThirdParties from "./third-parties";

const REQUIRE_SUMSUB_VERIFICATION = true;

const validateInvestor = (investor: Partial<Investor>): string | undefined => {
  if (investor.type === InvestorType.Entity) {
    if (!investor.entity_name) {
      return "Entity Name";
    }

    if (!investor.entity_phone) {
      return "Main Phone";
    }

    if (!investor.entity_email) {
      return "Entity Email";
    }

    if (!investor.entity_registered_address) {
      return "Registered Address";
    }

    if (!investor.auth_person_name) {
      return "Name of Authorized Person";
    }

    if (!investor.auth_person_title) {
      return "Title of Authorized Person";
    }

    if (!investor.auth_person_email) {
      return "Email of Authorized Person";
    }

    if (!investor.auth_person_phone) {
      return "Phone Number of Authorized Person";
    }

    if (!investor.legal_entity_type) {
      return "Legal entity type";
    }
  }

  if (investor.type === InvestorType.Individual) {
    if (!investor.individual_acknowledge_rdi) {
      return "RDI Acknowledgement";
    }

    if (!investor.individual_name) {
      return "Individual Name";
    }

    if (!investor.individual_phone) {
      return "Individual Phone";
    }

    if (!investor.individual_email) {
      return "Individual Email";
    }

    if (!investor.individual_address) {
      return "Individual Address";
    }

    if (!investor.individual_address) {
      return "Individual Occupation";
    }

    if (!investor.individual_dob) {
      return "Individual Date of Birth";
    }
  }

  if (
    investor.tax_jurisdiction === TaxJurisdiction.Other &&
    !(investor.tax_jurisdiction_other ?? "").trim()
  ) {
    return "Tax Jurisdiction (Other)";
  }

  if (
    !investor.waive_suitability_via_permitted_client &&
    !investor.waive_suitability_via_assets
  ) {
    if (!investor.investment_objective) {
      return "Investment Objective";
    }

    if (!investor.time_horizon) {
      return "Time Horizon";
    }

    if (!investor.transaction_freq) {
      return "Transaction Frequency";
    }

    if (!investor.investment_knowledge) {
      return "Investment Knowledge";
    }

    if (!investor.risk_profile) {
      return "Risk Profile";
    }
  }
};

const getTenantSumSubURL = (tenant: Tenant, type?: InvestorType) => {
  const USE_DEFAULT_URL = true;
  if (USE_DEFAULT_URL) {
    return "https://in.sumsub.com/idensic/l/#/uni_JprbsOFSwQI8CdL7";
  }

  switch (tenant.toLowerCase()) {
    case Tenant.Homeslice.toLowerCase(): {
      switch (type) {
        case InvestorType.Entity: {
          return "https://in.sumsub.com/idensic/l/#/uni_37QhfviEVpmYlV4r";
        }

        case InvestorType.Individual:
        default: {
          return "https://in.sumsub.com/idensic/l/#/uni_JprbsOFSwQI8CdL7";
        }
      }
    }

    default: {
      switch (type) {
        case InvestorType.Entity: {
          return "https://in.sumsub.com/idensic/l/#/uni_37QhfviEVpmYlV4r";
        }

        case InvestorType.Individual:
        default: {
          return "https://in.sumsub.com/idensic/l/#/uni_JprbsOFSwQI8CdL7";
        }
      }
    }
  }
};

interface Props {
  tenant: Tenant;
  cookiesCx: CookiesInterface;
  skipValidation: boolean;
}

interface State {
  loading: boolean;
  passedReCAPTCHA: boolean;
}

export class InvestorIntakeForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      passedReCAPTCHA: !env.production,
    };

    this.renderForm = this.renderForm.bind(this);
    this.renderResetTrigger = this.renderResetTrigger.bind(this);
    this.renderSummary = this.renderSummary.bind(this);
    this.submit = this.submit.bind(this);
  }

  private async submit() {
    if (env.production && !this.state.passedReCAPTCHA) {
      showToast({
        title: "Invalid Submission",
        description: "Please complete the reCAPTCHA challenge first.",
      });
      return;
    }

    const flagRequiredInput = (i18Key: string) =>
      showToast({
        title: "Required Input",
        description: t("x-is-required", { x: t(i18Key) }),
      });

    if (!this.props.tenant || this.props.tenant === Tenant.None) {
      flagRequiredInput("Tenant");
      return;
    }

    const payload = this.props.cookiesCx.investor;

    if (env.production || !this.props.skipValidation) {
      if (!this.state.passedReCAPTCHA) {
        flagRequiredInput("ReCAPTCHA");
        return;
      }

      const missingInput = validateInvestor(payload);

      if (missingInput) {
        flagRequiredInput(missingInput);
        return;
      }
    }

    this.setState({ loading: true });

    const investor = await InvestorsService.getInstance()
      .postOne({
        ...payload,
        tenant: this.props.tenant,
      })
      .finally(() => this.setState({ loading: false }));

    if (investor) {
      showToast({
        title: "Success",
        description: "You submission has been received!",
      });

      this.props.cookiesCx.setCookie(CookieKey.Investor, investor);

      window.scrollTo(0, 0);
    }
  }

  private renderResetTrigger() {
    return (
      <AlertDialog.Root>
        <AlertDialog.Trigger>
          <Button color="red" variant="soft">
            {t("Reset")}
          </Button>
        </AlertDialog.Trigger>
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>{t("Reset")}</AlertDialog.Title>
          <AlertDialog.Description size="2">
            The form will be returned to its default state, are you sure?
          </AlertDialog.Description>

          <Flex gap="2" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                {t("Cancel")}
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                variant="solid"
                color="red"
                onClick={() =>
                  this.props.cookiesCx
                    .clearCookie(CookieKey.Investor)
                    .then(() => {
                      window.scrollTo(0, 0);
                      window.location.reload();
                    })
                }
              >
                {t("Reset")}
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }

  render(): React.ReactNode {
    const investor = this.props.cookiesCx.investor;

    return (
      <ErrorBoundary id="InvestorIntakeForm">
        <Flex direction="column" gap="8" mt="4" mb="4">
          {!!investor._created
            ? this.renderSummary(investor)
            : this.renderForm(investor)}
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderSummary(investor: Partial<Investor>): React.ReactNode {
    return (
      <Flex direction="column" gap="4">
        <Heading>Your submission has been received.</Heading>

        <Text size="1">
          Submission ID:{" "}
          <Tooltip
            content={
              <Text>
                Save this for your own records and include it on any
                communications with support.
              </Text>
            }
          >
            <Code
              className="clickable"
              onClick={() => {
                if (!investor._id) {
                  return;
                }

                navigator.clipboard.writeText(investor._id);

                showToast({
                  title: "Submission ID",
                  description: "Copied to clipboard!",
                });
              }}
            >
              {investor._id}
            </Code>
          </Tooltip>
        </Text>

        {REQUIRE_SUMSUB_VERIFICATION && (
          <>
            <Text>
              Please complete a quick identity verification process via{" "}
              <Link href="https://sumsub.com/" target="__blank">
                Sum & Substance
              </Link>{" "}
              to continue.
            </Text>

            <Flex gap="2">
              <Button
                color="green"
                onClick={() =>
                  window.open(
                    getTenantSumSubURL(this.props.tenant, investor.type)
                  )
                }
              >
                Proceed
              </Button>
              {this.renderResetTrigger()}
            </Flex>
          </>
        )}

        <Separator mt="8" size="4" />

        <details>
          <summary>Review Submission</summary>

          {this.renderForm(investor)}
        </details>
      </Flex>
    );
  }

  private renderForm(investor: Partial<Investor>) {
    const disabled =
      this.state.loading ||
      !!investor._created ||
      this.props.tenant === Tenant.None;

    return (
      <Flex direction="column" gap="8" style={{ maxWidth: "600px" }}>
        {FormHelper.renderRadio({
          id: "type",
          label:
            "Are you investing as an individual or on behalf of an entity?",
          defaultValue: investor.type ?? InvestorType.Individual,
          disabled: disabled,
          options: Object.values(InvestorType).map((v) => ({
            i18Key: v,
            value: v,
          })),
          onValueChange: (value) => {
            this.props.cookiesCx.setCookie(CookieKey.Investor, {
              type: value as InvestorType,
            });
          },
        })}

        {this.props.cookiesCx.investor.type === InvestorType.Individual && (
          <IndividualForm
            disabled={disabled}
            cookiesCx={this.props.cookiesCx}
          />
        )}

        {this.props.cookiesCx.investor.type === InvestorType.Entity && (
          <EntityForm disabled={disabled} cookiesCx={this.props.cookiesCx} />
        )}

        <ThirdParties disabled={disabled} cookiesCx={this.props.cookiesCx} />

        <SuitabilityForm disabled={disabled} cookiesCx={this.props.cookiesCx} />

        <PoliticalExposureForm
          disabled={disabled}
          cookiesCx={this.props.cookiesCx}
        />

        <Flex direction="column" gap="2">
          <ReCAPTCHAWrapper
            onChange={(token) => {
              if (!this.state.passedReCAPTCHA && !!token) {
                this.setState({ passedReCAPTCHA: true });
              }
            }}
          />

          <Flex gap="2">
            <Button disabled={disabled} onClick={() => this.submit()}>
              {t("Submit")}
            </Button>

            {this.renderResetTrigger()}
          </Flex>
        </Flex>

        <Footnotes />
      </Flex>
    );
  }
}
