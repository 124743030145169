import { ErrorBoundary } from "components/common/error-boundary";
import { DEFAULT_INVESTOR, Investor } from "interfaces/common/investor";
import { FC, ReactNode, createContext, useState } from "react";
import { useCookies } from "react-cookie";

type ValidCookie = Partial<Investor>;

/** this must be a string enum to work */
export enum CookieKey {
  Investor = "investor",
}

export interface CookiesInterface {
  investor: Partial<Investor>;
  readonly setCookie: (key: CookieKey, value: ValidCookie) => Promise<boolean>;
  readonly clearCookie: (key: CookieKey) => Promise<boolean>;
}

const DEFAULT: CookiesInterface = {
  investor: DEFAULT_INVESTOR,
  setCookie: () => new Promise(() => console.debug("not init")),
  clearCookie: () => new Promise(() => console.debug("not init")),
};

/** for manipulating anything that needs to load from or save to a cookie */
export const CookiesContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const CookiesProvider: FC<IProps> = (props) => {
  const [_init, _setInit] = useState(false);

  const [_cookie, _setCookie] = useCookies([CookieKey.Investor]);
  const [_investor, _setInvestor] = useState(DEFAULT.investor);

  const getCurrentValue = (key: CookieKey) => {
    switch (key) {
      case CookieKey.Investor: {
        return _investor;
      }

      default: {
        return undefined;
      }
    }
  };

  const state: CookiesInterface = {
    investor: _investor,

    clearCookie: (key) => {
      return new Promise<boolean>((resolve) => {
        try {
          switch (key) {
            case CookieKey.Investor: {
              _setCookie(CookieKey.Investor, DEFAULT.investor);
              _setInvestor({});
              break;
            }

            default: {
              break;
            }
          }

          resolve(true);
        } catch (e) {
          console.error(e);
          resolve(false);
        }
      });
    },

    setCookie: (key, value) => {
      return new Promise<boolean>((resolve) => {
        try {
          const nextValue: any = {
            ...getCurrentValue(key),
            ...value,
          };

          switch (key) {
            case CookieKey.Investor: {
              _setCookie(CookieKey.Investor, nextValue);
              _setInvestor(nextValue);
              break;
            }

            default: {
              break;
            }
          }

          resolve(true);
        } catch (e) {
          console.error(e);
          resolve(false);
        }
      });
    },
  };

  /** ensures the following only runs once, on load, without tripping useEffect warnings */
  if (!_init) {
    const defaultInvestor: Investor = {
      ..._investor,
      ..._cookie.investor,
    };

    _setCookie(CookieKey.Investor, defaultInvestor);
    _setInvestor(defaultInvestor);

    console.debug({
      event: "initialized cookies context",
      investor: defaultInvestor,
    });

    _setInit(true);
  }

  return (
    <ErrorBoundary id="CookiesContext">
      <CookiesContext.Provider value={state}>
        {props.children}
      </CookiesContext.Provider>
    </ErrorBoundary>
  );
};
