import ReCAPTCHA from "react-google-recaptcha";
import { ErrorBoundary } from "./error-boundary";

// NOTE: https://www.google.com/recaptcha/admin/create
// use classic v2 keys
// server secret key 6LeQ8SkpAAAAAJSoCa2uv1CCim3ZQkRNETQTqidI

interface Props {
  onChange: (token: string | null) => void;
}

const ReCAPTCHAWrapper = (props: Props) => {
  return (
    <ErrorBoundary id="ReCAPTCHAWrapper">
      <ReCAPTCHA
        sitekey="6LeQ8SkpAAAAAGuOpP5bEw5Zj_u-P_lgdj7N2zQm"
        onChange={props.onChange}
      />
    </ErrorBoundary>
  );
};

export default ReCAPTCHAWrapper;
