import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const IndividualSpouse = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="IndividualSpouse">
      <Flex direction="column" gap="4">
        {FormHelper.renderTextField({
          label: "Spouse/Partner Name",
          defaultValue: investor.spouse_name,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              spouse_name: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          label: "Spouse/Partner Occupation",
          defaultValue: investor.spouse_occupation,
          placeholder:
            "(Be specific--if retired, please enter their previous occupation)",
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              spouse_occupation: e.target.value ?? "",
            }),
        })}
      </Flex>
    </ErrorBoundary>
  );
};

export default IndividualSpouse;
