import { EnvConfig, RELEASE_VERSION } from "interfaces/env-config";

export const ENV_PROD: EnvConfig = {
  release: RELEASE_VERSION,

  identifier: "prod",
  maintenance: false,
  strict: true,

  production: true,
  debug_log: false,

  self_url: "https://emd.bridgeme.ca",
  server_url: "https://emd.bridgeme.ca/api",
};
