import env from "config";
import { IAuthContext } from "contexts/auth";
import LogRocket from "logrocket";
import { createContext, FC, useEffect, useState } from "react";

const CONTEXT_NAME = "LogRocketContext";

const APP_ID = "56diib/bridgeme-emd";

interface IProps {
  authCx: IAuthContext;
}

export interface ILogRocketContext {}

const DEFAULT: ILogRocketContext = {};

export const LogRocketContext = createContext(DEFAULT);

/** for tracking misc details across entire app */
export const LogRocketProvider: FC<IProps> = (props) => {
  const [init, setInit] = useState(false);

  const state: ILogRocketContext = {};

  useEffect(() => {
    if (init) {
      LogRocket.startNewSession();
      console.debug(`${CONTEXT_NAME}: starting new session`);
    } else {
      LogRocket.init(APP_ID, {
        release: env.release,
      });

      setInit(true);
      console.debug(`${CONTEXT_NAME}: initializing`);
    }

    if (props.authCx.user?.email) {
      LogRocket.identify(props.authCx.user.email, {
        env: env.identifier,
      });
    }
  }, [props.authCx.user]);

  return <LogRocketContext.Provider value={state} />;
};
