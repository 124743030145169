import {
  EnvelopeClosedIcon,
  MobileIcon,
  SewingPinFilledIcon,
} from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const EntityDetails = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="EntityDetails">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("Entity Details")}

        {FormHelper.renderTextField({
          label: "Entity Name",
          defaultValue: investor.entity_name,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              entity_name: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <MobileIcon />,
          label: "Main Phone",
          defaultValue: investor.entity_phone,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              entity_phone: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <EnvelopeClosedIcon />,
          label: "Email",
          defaultValue: investor.entity_email,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              entity_email: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <SewingPinFilledIcon />,
          label: "Registered Address",
          defaultValue: investor.entity_registered_address,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              entity_registered_address: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <SewingPinFilledIcon />,
          label: "Mailing Address (if different)",
          defaultValue: investor.entity_mailing_address,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              entity_mailing_address: e.target.value ?? "",
            }),
        })}
      </Flex>
    </ErrorBoundary>
  );
};

export default EntityDetails;
