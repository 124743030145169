import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Box, Flex, Heading, Strong, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";

const UserProfile = () => {
  const { user } = useAuth0();

  if (!user) {
    return <></>;
  }

  return (
    <ErrorBoundary id="UserProfile">
      <Flex direction="column" gap="4">
        <Heading>User Profile</Heading>

        <Flex gap="4">
          <Box width="9">
            <img style={{ width: "100%" }} src={user.picture} alt={user.name} />
          </Box>
          <Box>
            <Text>
              Welcome back <Strong>{user.email}</Strong>{" "}
              <Badge color={user.email_verified ? "green" : "red"}>
                {user.email_verified ? "Verified" : "Unverified"}
              </Badge>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export default UserProfile;
