import { Flex, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "./form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const ThirdParties = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="ThirdParties">
      <Flex direction="column" gap="4">
        {FormHelper.renderRadio({
          id: "net_assets_over_25MM",
          label:
            "Are there any other party(ies) other than the account owner that have a financial interest in the account? i.e. A person or entity that is not listed as an account holder for which makes investment decisions or controls the account.",
          defaultValue: investor.third_parties ? "true" : "false",
          disabled: props.disabled,
          options: [
            { i18Key: "Yes", value: "true" },
            { i18Key: "No", value: "false" },
          ],
          onValueChange: (value) => {
            const nextValue = value === "true";
            props.cookiesCx.setCookie(CookieKey.Investor, {
              third_parties: nextValue,
            });
          },
        })}

        {investor.third_parties && (
          <>
            <Text>
              For each third party that is an individual, please provide the
              following in the box below:
              <ul>
                <li>Individual's name</li>
                <li>Address</li>
                <li>Phone number</li>
                <li>Date of birth</li>
              </ul>
            </Text>
            <Text>
              For each third party that is a corporation, please provide the
              following in the box below:
              <ul>
                <li>Corporation's name</li>
                <li>Incorporation number</li>
                <li>Place of incorporation</li>
                <li>Principal business</li>
              </ul>
            </Text>

            <Text>
              For each third party, please describe the relationship with the
              account holder.
            </Text>

            {FormHelper.renderTextArea({
              defaultValue: investor.third_parties_desc,
              disabled: props.disabled,
              onChange: (e) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  third_parties_desc: e.target.value ?? "",
                }),
            })}
          </>
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export default ThirdParties;
