export class MiscHelper {
  /** await this to delay execution in sync functions */
  static sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  /** returns a sorted array of unique values based on nonUnique input */
  static saveAs(blob: Blob, fileNameWithExt: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = fileNameWithExt;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
