import { Button, Callout, Flex, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { showToast } from "components/common/toast-listener";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { DOC_PATHS } from "enums/docs";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const RelationshipDisclosure = (props: Props) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // track whether user has clicked on the button to open the modal
  const [clicked, setClicked] = useState(false);
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="RelationshipDisclosure">
      <Flex direction="column" gap="4">
        <Callout.Root>
          <Callout.Text>
            <Flex gap="2" direction="column">
              <Text>
                You must review our Relationship Disclosure Information (RDI)
                before proceeding further.
              </Text>

              <Flex justify="start">
                <Button
                  onClick={() => {
                    window.open(DOC_PATHS.RDI);
                    setClicked(true);
                  }}
                >
                  View RDI
                </Button>
              </Flex>
            </Flex>
          </Callout.Text>
        </Callout.Root>

        <Callout.Root
          variant="outline"
          color="orange"
          className={clicked ? undefined : "not-clickable"}
          onClick={() => {
            if (!clicked) {
              showToast({
                title: "Invalid Input",
                description: "Please view the RDI first.",
              });
            }
          }}
        >
          <Callout.Text>
            {FormHelper.renderCheckbox({
              id: "waive_suitability_via_permitted_client",
              label:
                "I have read and downloaded the Relationship Disclosure Information.",
              defaultChecked: !!investor.individual_acknowledge_rdi,
              disabled:
                props.disabled ||
                (!clicked && !investor.individual_acknowledge_rdi),
              onCheckedChange: (value) =>
                props.cookiesCx.setCookie(CookieKey.Investor, {
                  individual_acknowledge_rdi:
                    value === true || value === "true",
                }),
            })}
          </Callout.Text>
        </Callout.Root>
      </Flex>
    </ErrorBoundary>
  );
};

export default RelationshipDisclosure;
