import { EnvelopeClosedIcon, MobileIcon } from "@radix-ui/react-icons";
import { Flex, Text } from "@radix-ui/themes";
import { ErrorBoundary } from "components/common/error-boundary";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { FormHelper } from "../form.helper";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const EntityAuthorizedPerson = (props: Props) => {
  const investor = props.cookiesCx.investor;

  return (
    <ErrorBoundary id="EntityAuthorizedPerson">
      <Flex direction="column" gap="4">
        {FormHelper.renderHeader("Authorized Person")}

        {FormHelper.renderTextField({
          label: "Name of Authorized Person",
          defaultValue: investor.auth_person_name,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              auth_person_name: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          label: "Title of Authorized Person",
          defaultValue: investor.auth_person_title,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              auth_person_title: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <EnvelopeClosedIcon />,
          label: "Email of Authorized Person",
          defaultValue: investor.auth_person_email,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              auth_person_email: e.target.value ?? "",
            }),
        })}

        {FormHelper.renderTextField({
          slot: <MobileIcon />,
          label: "Phone Number of Authorized Person",
          defaultValue: investor.auth_person_phone,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              auth_person_phone: e.target.value ?? "",
            }),
        })}

        <Text>Additional authorized persons can be added later.</Text>
      </Flex>
    </ErrorBoundary>
  );
};

export default EntityAuthorizedPerson;
