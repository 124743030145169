import { ToastProvider } from "@radix-ui/react-toast";
import { Box, Container, Flex, Theme } from "@radix-ui/themes";
import { ThemeProps } from "@radix-ui/themes/dist/cjs/theme";
import AdminDashboard from "components/admin-dashboard";
import { ErrorBoundary } from "components/common/error-boundary";
import { ToastListener } from "components/common/toast-listener";
import { InvestorIntakeForm } from "components/investor-intake-form";
import Disclaimer from "components/layout/disclaimer";
import Header from "components/layout/header";
import env from "config";
import { AuthContext, AuthProvider } from "contexts/auth";
import { CookiesContext, CookiesProvider } from "contexts/cookies";
import { LogRocketProvider } from "contexts/log-rocket.context";
import { AppRoute } from "enums/router";
import { Tenant } from "enums/tenants";
import { Route, Routes, useSearchParams } from "react-router-dom";
import i18n from "translations/i18n";
import "./App.scss";

const getTenantTheme = (tenant: Tenant): Partial<ThemeProps> => {
  switch (tenant) {
    case Tenant.Homeslice: {
      return {
        accentColor: "blue",
        grayColor: "slate",
      };
    }

    default: {
      return {
        accentColor: "indigo",
        grayColor: "slate",
      };
    }
  }
};

function App() {
  if (!env.debug_log) {
    console.debug = () => {};
  }

  i18n.init((error) => {
    if (error) {
      console.error(error);
    } else {
      console.debug(`i18next initialized w/ ${i18n.language}`);
    }
  });

  const [searchParams] = useSearchParams();

  const tenant = (() => {
    const value = searchParams.get("tenant") as Tenant;

    if (!value) {
      return Tenant.None;
    }

    return value;
  })();

  const themeConfig = getTenantTheme(tenant);

  const skipValidation = searchParams.get("skipValidation") === "true";

  return (
    <ErrorBoundary id="App">
      <AuthProvider>
        <AuthContext.Consumer>
          {(authCx) => (
            <Theme {...(themeConfig as Partial<ThemeProps>)}>
              <ToastProvider swipeDirection="left" duration={3_000}>
                <ToastListener position="top-right" />
                <LogRocketProvider authCx={authCx} />
                <div id="Main">
                  <div id="Header">
                    <Routes>
                      <Route
                        path={AppRoute.Investor}
                        element={<Header tenant={tenant} />}
                      />
                      <Route
                        path="*"
                        element={<Header tenant={tenant} authCx={authCx} />}
                      />
                    </Routes>
                  </div>

                  <div id="Content">
                    <Container size="3">
                      <Box pt="8" pb="8">
                        <Routes>
                          <Route
                            path={AppRoute.Investor}
                            element={
                              <CookiesProvider>
                                <CookiesContext.Consumer>
                                  {(cookiesCx) => (
                                    <InvestorIntakeForm
                                      tenant={tenant}
                                      cookiesCx={cookiesCx}
                                      skipValidation={skipValidation}
                                    />
                                  )}
                                </CookiesContext.Consumer>
                              </CookiesProvider>
                            }
                          />

                          <Route
                            path={AppRoute.Root}
                            element={<AdminDashboard />}
                          />
                        </Routes>
                      </Box>
                    </Container>
                    <Disclaimer />
                  </div>

                  <div id="Footer">
                    <Container size="3">
                      <Flex pt="4" pb="4">
                        <Box>Powered by BridgeMe</Box>
                        <Box grow="1"></Box>
                        <Box>©BridgeMe</Box>
                      </Flex>
                    </Container>
                  </div>
                </div>
              </ToastProvider>
            </Theme>
          )}
        </AuthContext.Consumer>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
