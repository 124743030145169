import { Flex, Heading, Text } from "@radix-ui/themes";
import UserProfile from "components/auth0/user-profile";
import { ErrorBoundary } from "components/common/error-boundary";
import { AuthContext } from "contexts/auth";
import { Tenant } from "enums/tenants";
import { InvestorsListing, WILDCARD_TENANT } from "./investors-listing";

const getTenantForQuery = (email?: string) => {
  if (!email) {
    return;
  }

  if (email.endsWith("joinhomeslice.ca")) {
    return Tenant.Homeslice;
  }

  if (email.endsWith("bridgeme.ca")) {
    return WILDCARD_TENANT;
  }
};

const AdminDashboard = () => {
  return (
    <ErrorBoundary id="AdminDashboard">
      <AuthContext.Consumer>
        {(authCx) => {
          return (
            <Flex direction="column" gap="4">
              <Heading>Welcome back</Heading>

              <UserProfile />

              {(() => {
                if (authCx.loading) {
                  return (
                    <Text color="yellow">Authenticating, please wait...</Text>
                  );
                }

                if (!authCx.token) {
                  return (
                    <Flex gap="4" direction="column">
                      <Text>
                        Log In or Sign Up to access the tenant dashboard.
                      </Text>
                    </Flex>
                  );
                }

                if (!authCx.user?.email_verified) {
                  return (
                    <Flex gap="4" direction="column">
                      <Text>
                        Verify your email to access the tenant dashboard.
                      </Text>
                    </Flex>
                  );
                }

                const emailTenant = getTenantForQuery(authCx.user?.email);
                if (!emailTenant) {
                  return (
                    <Text color="red">
                      Your email is not associated with any tenant. Please
                      contact support.
                    </Text>
                  );
                }

                return <InvestorsListing tenant={emailTenant} />;
              })()}
            </Flex>
          );
        }}
      </AuthContext.Consumer>
    </ErrorBoundary>
  );
};

export default AdminDashboard;
