import { FormHelper } from "components/investor-intake-form/form.helper";
import { CookieKey, CookiesInterface } from "contexts/cookies";
import { TaxJurisdiction } from "enums/common/investors";
import { ErrorBoundary } from "./error-boundary";

interface Props {
  disabled: boolean;
  cookiesCx: CookiesInterface;
}

const TaxJurisdictionInputs = (props: Props) => {
  const investor = props.cookiesCx.investor;
  return (
    <ErrorBoundary id="TaxJurisdictionInputs">
      {FormHelper.renderSelect({
        id: "tax_jurisdiction",
        label: "Tax Jurisdiction / Residency",
        placeholder: "Select one",
        defaultValue: investor.tax_jurisdiction,
        disabled: props.disabled,
        options: Object.values(TaxJurisdiction).map((o) => ({
          i18Key: o,
          value: o,
        })),
        onValueChange: (value) =>
          props.cookiesCx.setCookie(CookieKey.Investor, {
            tax_jurisdiction: value as TaxJurisdiction,
          }),
      })}

      {investor.tax_jurisdiction === TaxJurisdiction.Other &&
        FormHelper.renderTextField({
          placeholder: "Please provide the country",
          defaultValue: investor.tax_jurisdiction_other,
          disabled: props.disabled,
          onChange: (e) =>
            props.cookiesCx.setCookie(CookieKey.Investor, {
              tax_jurisdiction_other: e.target.value ?? "",
            }),
        })}
    </ErrorBoundary>
  );
};

export default TaxJurisdictionInputs;
